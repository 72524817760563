import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';

import './CheckoutForm.scss';

class CheckoutForm extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.onCardChange = this.onCardChange.bind(this);

    this.state = {
      disabled: true,
      error: null
    };
  }

  submit (event) {
    event.preventDefault();

    const firebaseUID = this.props.authUser.uid;

    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    //this.props.stripe.createToken({name: 'Jenny Rosen'}).then(({token}) => {
    //  console.log('Received Stripe token:', token);
    //});

    this.props.stripe.createToken().then(function(result) {
      if (result.error) {
        // Inform the customer that there was an error.
        this.setState({
          disabled: true,
          error: result.error
        })
      } else {

        fetch('https://api.useragentapi.io/account/payment', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: result.token.id,
            uid: firebaseUID
          })
        }).then(function(response) {
          if (response.ok) {
            return response;
          }
          throw Error(response.statusText);
        }).then(function(response) {
          return response.json();
        }).then((json) => {

          console.log(json);

        }).catch((error) => {

          console.log(error);

        });

      }
    });
  }

  onCardChange = (event) => {
    this.setState({
      disabled: event.error ? true : false,
      error: event.error
    })
  }

  componentDidMount() {
    const thisProps = this.props;
  }

  render() {
    return (
      <div className="checkout">
        {this.state.error &&
          <div className="alert alert-danger" id="card-errors" role="alert">{this.state.error.message}</div>}

        <CardElement onChange={this.onCardChange} />

        <button
          className="btn"
          disabled={this.state.disabled}
          onClick={this.submit}
        >Send</button>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);