import React, { Component } from 'react'

import './AuthWrapper.scss';
import { ReactComponent as Logo } from '../../assets/svg/user-agent-nav-logo.svg';

class AuthWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      children: this.props.children
    }
  }

  render() {
    return (
      <div id="auth-wrapper">
        <div className="auth-wrapper-content">
          <div className="auth-form">
            <Logo className="m-b-1" />
            {this.state.children}
          </div>
          <div className="auth-promo">
            <h2>No libraries.<br />No downloads.</h2>
            <p>Use our API and you'll always be using the latest version of the user agent parser.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthWrapper;
