import React from 'react';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faServer } from '@fortawesome/free-solid-svg-icons';

import AuthUserContext from '../../context/AuthUserContext';
import * as routes from '../../constants/routes';

const Navigation = () =>
  <AuthUserContext.Consumer>
    {authUser => authUser
      ? <NavigationAuth />
      : <NavigationNonAuth />
    }
  </AuthUserContext.Consumer>

const NavigationAuth = () =>
  <nav>
    <ul>
      <li>
        <NavLink exact to={routes.DASHBOARD}>
          <FontAwesomeIcon
            className="m-r-1"
            fixedWidth
            icon={faColumns}
          /> Dashboard
        </NavLink>
      </li>
      <li>
        <NavLink exact to={routes.ACCOUNT}>
          <FontAwesomeIcon
            className="m-r-1"
            fixedWidth
            icon={faUser}
          /> Account
        </NavLink>
      </li>
      <li>
        <NavLink exact to={routes.SUBSCRIPTION}>
          <FontAwesomeIcon
            className="m-r-1"
            fixedWidth
            icon={faServer}
          /> Subscription
        </NavLink>
      </li>
    </ul>
  </nav>

const NavigationNonAuth = () =>
  <ul>
    <li><NavLink to={routes.LOG_IN}>Sign In</NavLink></li>
  </ul>

export default Navigation;