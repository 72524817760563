import React, { Component } from 'react';

import Alert from '../shared/Alert';

class SubscribeAlerts extends Component {
  constructor(props) {
    super(props);

    this.state = {props}
  }

  static getDerivedStateFromProps(props, state) {
    if (props.stripeSubscriptionID !== state.prevProps.stripeSubscriptionID) {
      return {
        prevProps: props,
        stripeSubscriptionID: props.stripeSubscriptionID
      };
    } else {
      return null;
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.stripeSubscriptionID !== state.stripeSubscriptionID) {
      return { stripeSubscriptionID: props.stripeSubscriptionID };
    }
    return null;
  }

  render() {
    const { stripeCardToken, stripeSubscriptionID } = this.state;
    let alertComponent;

    if (stripeCardToken && stripeSubscriptionID) {
      alertComponent = <Alert
        message='Your subscription is active.'
        type='success'
      />;
    } else if(stripeSubscriptionID == null) {
      alertComponent = <Alert
        message='You have not choosen a subscription. Please make a selection below.'
        type='warning'
      />;
    } else if(stripeCardToken == null) {
      alertComponent = <Alert
        message='Please add a credit card to your account.'
        type='danger'
      />;
    }

    return (
      <>
        {alertComponent}
      </>
    );
  }
}

export default SubscribeAlerts;
