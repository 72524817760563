import React from 'react';

import AuthUserContext from '../../context/AuthUserContext';
import withAuthorization from '../auth/withAuthorization';

import AuthorizedWrapper from '../shared/AuthorizedWrapper';

import { auth, db } from '../../firebase';

import {Elements, StripeProvider} from 'react-stripe-elements';

import SubscribeAlerts from './SubscribeAlerts';
import PricingTable from './PricingTable';
import CheckoutForm from './CheckoutForm';

class SubscriptionPage extends React.Component {
  constructor() {
    super();

    this.saveSubscriptionCall = this.saveSubscriptionCall.bind(this);
    this.saveSubscription = this.saveSubscription.bind(this);

    this.state = {
      stripe: null,
      stripeCardToken: null,
      stripeSubscriptionID: null
    };
  }

  saveSubscriptionCall(subscription_id) {
    const firebaseUID = auth.getAuthUID();

    this.setState({
      globalDisabled: true,
      spinIcon: true
    });

    fetch('https://api.useragentapi.io/account/subscribe', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subscription_id: subscription_id,
        uid: firebaseUID
      })
    }).then(function(response) {
      if (response.ok) {
        return response;
      }
      throw Error(response.statusText);
    }).then(function(response) {
      return response.json();
    }).then((json) => {
      this.setState({
        globalDisabled: false,
        spinIcon: false,
        stripeSubscriptionID: json.message
      });
    }).catch((error) => {
      this.setState({
        globalDisabled: false,
        error: error.message,
        spinIcon: false
      });
    });
  }

  saveSubscription(event) {
    const subscription_id = event;

    if(this.state.stripeSubscriptionID) {
      if (window.confirm('Are you sure you want to change your subscription?')) {
        this.saveSubscriptionCall(subscription_id);
      }
    } else {
      this.saveSubscriptionCall(subscription_id);
    }
  }

  componentDidMount() {
    db.onceGetCustomer(auth.getAuthUID()).then(snapshot =>
      this.setState({
        stripeCardToken: snapshot.val().stripe_card_token,
        stripeSubscriptionID: snapshot.val().stripe_subscription_id
      })
    );

    if (window.Stripe) {
      this.setState({stripe: window.Stripe('pk_test_Mz3UjSh8OVWNQgw0zsfGVwZm')});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({stripe: window.Stripe('pk_test_Mz3UjSh8OVWNQgw0zsfGVwZm')});
      });
    }
  }

  render() {
    // this.state.stripe will either be null or a Stripe instance
    // depending on whether Stripe.js has loaded.
    return (
      <AuthorizedWrapper>
        <AuthUserContext.Consumer>
          { authUser =>
            <>
              <h1 className="page-title">Subscription</h1>

              {this.state.error &&
                <div className="alert alert-danger">{this.state.error}</div>
              }

              <SubscribeAlerts
                stripeCardToken={this.state.stripeCardToken}
                stripeSubscriptionID={this.state.stripeSubscriptionID}
              />

              <div className="card">
                <h3>Plan</h3>
                <PricingTable
                  saveSubscription={this.saveSubscription}
                  stripeSubscriptionID={this.state.stripeSubscriptionID}
                />
              </div>

              {this.state.stripeSubscriptionID &&
                <div className="card">
                  <h3>Credit Card</h3>
                  <StripeProvider stripe={this.state.stripe}>
                    <Elements>
                      <CheckoutForm
                        authUser={authUser}
                      />
                    </Elements>
                  </StripeProvider>
                </div>
              }
            </>
          }
        </AuthUserContext.Consumer>
      </AuthorizedWrapper>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(SubscriptionPage);