import React from 'react';

import Year from '../shared/Year';

const Foooter = () =>
  <footer>
    Copyright &copy; <Year /> UserAgentAPI.io. All rights reserved.
  </footer>

export default Foooter;
