import React from 'react';

import AuthUserContext from '../../context/AuthUserContext';
import withAuthorization from '../auth/withAuthorization';

import AuthorizedWrapper from '../shared/AuthorizedWrapper';

import { auth, db } from '../../firebase';

import ApiKeyCard from './ApiKeyCard';
import EmailChangeForm from './EmailChange';
import PasswordChangeForm from './PasswordChange';

class AccountPage extends React.Component {
  constructor() {
    super();

    this.handleClickEditEmail = this.handleClickEditEmail.bind(this);
    this.handleClickEditPassword = this.handleClickEditPassword.bind(this);

    this.state = {
      editEmail: false,
      editPassword: false,
      api_key: null
    };
  }

  handleClickEditEmail() {
    this.setState(prevState => ({
      editEmail: !prevState.editEmail
    }));
  }

  handleClickEditPassword() {
    this.setState(prevState => ({
      editPassword: !prevState.editPassword
    }));
  }

  componentDidMount() {
    db.onceGetCustomer(auth.getAuthUID()).then(snapshot =>
      this.setState({
        api_key: snapshot.val().api_key
      })
    );
  }

  render() {
    return (
      <AuthorizedWrapper>
        <AuthUserContext.Consumer>
          {authUser =>
            <>
              <h1 className="page-title">Account</h1>

              <div className="card">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <h5>Signed Up</h5>
                    <p className="m-b-0">{authUser.metadata.creationTime}</p>
                  </div>
                  <div className="col-lg-6 col-12">
                    <h5>Last Logged In</h5>
                    <p className="m-b-0">{authUser.metadata.lastSignInTime}</p>
                  </div>
                </div>
              </div>

              <ApiKeyCard />

              <div className="card">
                {this.state.editEmail ? (
                  <>
                    <h4>
                      Email Address
                      <button
                        className="btn btn-sm btn-muted pull-right"
                        onClick={this.handleClickEditEmail}
                      >
                        Close
                      </button>
                    </h4>
                    <EmailChangeForm />
                  </>
                ) : (
                  <>
                    <h4>
                      Email Address
                      <button
                        className="btn btn-sm btn-warning pull-right"
                        onClick={this.handleClickEditEmail}
                      >
                        Edit
                      </button>
                    </h4>
                    <p className="m-b-0">{authUser.email}</p>
                  </>
                )}
              </div>

              <div className="card">
                {this.state.editPassword ? (
                  <>
                    <h4>
                      Password
                      <button
                        className="btn btn-sm btn-muted pull-right"
                        onClick={this.handleClickEditPassword}
                      >
                        Close
                      </button>
                    </h4>
                    <PasswordChangeForm />
                  </>
                ) : (
                  <>
                    <h4>
                      Password
                      <button
                        className="btn btn-sm btn-warning pull-right"
                        onClick={this.handleClickEditPassword}
                      >
                        Edit
                      </button>
                    </h4>
                    <p className="m-b-0">&#8226; &#8226; &#8226; &#8226; &#8226; &#8226; &#8226; &#8226; &#8226; &#8226;</p>
                  </>
                )}
              </div>

              
            </>
          }
        </AuthUserContext.Consumer>
      </AuthorizedWrapper>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(AccountPage);
