import React, { Component } from 'react';

import { auth } from '../../firebase';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  newEmail: '',
  newEmailTwo: '',
  error: null,
};

class EmailChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { newEmail } = this.state;

    auth.doEmailUpdate(newEmail)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      newEmail,
      newEmailTwo,
      error,
    } = this.state;

    const isInvalid =
      newEmail !== newEmailTwo ||
      newEmail === '';

    return (
      <div className="row">
        <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-12">
          <form onSubmit={this.onSubmit}>
            { error && <p className="alert alert-danger">{error.message}</p> }

            <label htmlFor="newEmail">New Email Address</label>
            <input
              autoFocus
              className="input-text"
              id="newEmail"
              onChange={event => this.setState(byPropKey('newEmail', event.target.value))}
              type="email"
              value={newEmail}
            />

            <label htmlFor="newEmailTwo">Confirm New Email Address</label>
            <input
              className="input-text"
              id="newEmailTwo"
              onChange={event => this.setState(byPropKey('newEmailTwo', event.target.value))}
              type="email"
              value={newEmailTwo}
            />

            <button
              className="btn btn-block"
              disabled={isInvalid}
              type="submit"
            >
              Change Account Email Address
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default EmailChangeForm;