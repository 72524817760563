import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

class PackageButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      disabled: false,
      prevProps: props,
      spinIcon: false,
      stripeSubscriptionID: null,
      stripeProdID: this.props.stripeProdID
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.stripeSubscriptionID !== state.prevProps.stripeSubscriptionID) {
      return {
        prevProps: props,
        saveSubscription: props.saveSubscription,
        stripeProdID: props.stripeProdID,
        stripeSubscriptionID: props.stripeSubscriptionID
      };
    }
    return null;
  }

  render() {
    const isDisabled = (this.state.stripeSubscriptionID === this.state.stripeProdID) ? true : false;
    let buttonText;

    if((this.state.stripeSubscriptionID == null && this.state.stripeProdID == null) || (this.state.spinIcon === true)) {
      buttonText = <FontAwesomeIcon
              fixedWidth
              icon={faSpinner}
              spin={this.state.spinIcon}
            />
    } else if(this.state.stripeSubscriptionID == null) {
      buttonText = 'Subscribe';
    } else if (this.state.stripeProdID < this.state.stripeSubscriptionID) {
      buttonText = 'Downgrade';
    } else if(this.state.stripeProdID === this.state.stripeSubscriptionID) {
      buttonText = 'Current Subscription';
    } else if(this.state.stripeProdID > this.state.stripeSubscriptionID) {
      buttonText = 'Upgrade';
    }

    return (
      <button
        className="btn btn-block"
        disabled={isDisabled}
        onClick={() => this.state.saveSubscription(this.state.stripeProdID)}
      >
        {buttonText}
      </button>
    );
  }
}

export default PackageButton;
