import { db } from './firebase';

// User API

/*
export const doCreateUser = (id, username, email) =>
  db.ref(`users/${id}`).set({
    username,
    email,
  });
*/

export const onceGetCustomer = (uid) =>
  db.ref(`profile/${uid}`).once('value');
