import React from 'react';

import AuthUserContext from '../../context/AuthUserContext';

import AuthorizedWrapper from '../shared/AuthorizedWrapper';

import './Dashboard.scss';

const DashboardPage = () =>
  <AuthorizedWrapper>
    <AuthUserContext.Consumer>
      {authUser =>
        <>
          <h1 className="page-title">Dashboard</h1>
          <div class="dashboard">
            <div class="dashboard__item">
              <div class="card">
                <strong>41</strong> Foobars
              </div>
            </div>
            <div class="dashboard__item">
              <div class="card">
                <strong>81.712</strong> Doohickeys
              </div>
            </div>
            <div class="dashboard__item dashboard__item--full">
              <div class="card">
                <img src="https://imgs.xkcd.com/comics/decline.png" alt="" />
              </div>
            </div>
            <div class="dashboard__item dashboard__item--col">
              <div class="card">{authUser.email}</div>
            </div>
            <div class="dashboard__item dashboard__item--col">
              <div class="card">B</div>
            </div>
            <div class="dashboard__item dashboard__item--col">
              <div class="card">C</div>
            </div>
            <div class="dashboard__item dashboard__item--col">
              <div class="card">D</div>
            </div>
          </div>
        </>
      }
    </AuthUserContext.Consumer>
  </AuthorizedWrapper>

export default DashboardPage;
