import React, { Component } from 'react';

import { auth } from '../../firebase';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { passwordOne } = this.state;

    auth.doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '';

    return (
      <div className="row">
        <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-12">
          <form onSubmit={this.onSubmit}>
            { error && <p className="alert alert-danger">{error.message}</p> }

            <label htmlFor="passwordOne">New Password</label>
            <input
              autoFocus
              className="input-text"
              id="passwordOne"
              onChange={event => this.setState(byPropKey('passwordOne', event.target.value))}
              type="password"
              value={passwordOne}
            />

            <label htmlFor="passwordTwo">Confirm New Password</label>
            <input
              className="input-text"
              id="passwordTwo"
              onChange={event => this.setState(byPropKey('passwordTwo', event.target.value))}
              type="password"
              value={passwordTwo}
            />

            <button
              className="btn btn-block"
              disabled={isInvalid}
              type="submit"
            >
              Reset My Password
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default PasswordChangeForm;