import React, {Component} from 'react';

import { auth, db } from '../../firebase';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/pro-regular-svg-icons';

class ApiKeyCard extends Component {
  constructor(props) {
    super(props);

    this.handleClickRegenateKey = this.handleClickRegenateKey.bind(this);

    this.state = {
      api_key: null,
      disabled: true,
      error: null,
      spinIcon: false
    };
  }

  handleClickRegenateKey(event) {
    event.preventDefault();

    const firebaseUID = auth.getAuthUID();

    this.setState({
      disabled: true,
      spinIcon: true
    });

    fetch('https://api.useragentapi.io/account/regenerate', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid: firebaseUID
      })
    }).then(function(response) {
      if (response.ok) {
        return response;
      }
      throw Error(response.statusText);
    }).then(function(response) {
      return response.json();
    }).then((json) => {
      this.setState({
        api_key: json.message,
        disabled: false,
        spinIcon: false
      });
    }).catch((error) => {
      this.setState({
        disabled: false,
        error: error.message,
        spinIcon: false
      });
    });

  }

  componentDidMount() {
    db.onceGetCustomer(auth.getAuthUID()).then(snapshot =>
      this.setState({
        api_key: snapshot.val().api_key,
        disabled: false
      })
    );
  }

  render() {
    return (
      <div className="card">
        {this.state.error &&
          <div className="alert alert-danger">{this.state.error}</div>}

        <h4>
          API Key
          <button
            className="btn btn-sm btn-warning pull-right"
            disabled={this.state.disabled}
            onClick={this.handleClickRegenateKey}
          >
            <FontAwesomeIcon
              fixedWidth
              icon={faSyncAlt}
              spin={this.state.spinIcon}
            /> Regenerate
          </button>
        </h4>
        <p className="m-b-0">{this.state.api_key}</p>
      </div>
    );
  }
}

export default ApiKeyCard;