import React, { Component } from 'react';



import PackageButton from './PackageButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import './PricingTable.scss';

class PricingTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      globalDisabled: false,
      prevProps: props,
      spinIcon: false,
      stripeSubscriptionID: null
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.stripeSubscriptionID !== state.prevProps.stripeSubscriptionID) {
      return {
        prevProps: props,
        saveSubscription: props.saveSubscription,
        stripeSubscriptionID: props.stripeSubscriptionID
      };
    }
    return null;
  }

  render() {
    return (
      <>
        <div className="pricing-table-container">
          <div className="package-container">
            <ul className="package">
              <li className="header">Standard</li>
              <li className="gray">$14.95/month</li>
              <li>5,000 Monthly Requests</li>
              <li>
                <FontAwesomeIcon
                  className="text-color-success"
                  fixedWidth
                  icon={faCheck}
                /> Browser Detection
              </li>
              <li>
                <FontAwesomeIcon
                  className="text-color-success"
                  fixedWidth
                  icon={faCheck}
                /> Device Detection
              </li>
              <li>
                <FontAwesomeIcon
                  className="text-color-success"
                  fixedWidth
                  icon={faCheck}
                /> SSL Encrypted Enpoint
              </li>
              <li>
                <FontAwesomeIcon
                  className="text-color-danger"
                  fixedWidth
                  icon={faTimes}
                /> User Location Detection
              </li>
              <li className="gray">
                <PackageButton
                  stripeSubscriptionID={this.state.stripeSubscriptionID}
                  saveSubscription={this.state.saveSubscription}
                  stripeProdID={'plan_Dgv8qhkENy5vf0'}
                />
              </li>
            </ul>
          </div>

          <div className="package-container">
            <ul className="package">
              <li className="header highlight">Pro</li>  
              <li className="gray">$49.95/month</li>
              <li>25,000 Monthly Requests</li>
              <li>
                <FontAwesomeIcon
                  className="text-color-success"
                  fixedWidth
                  icon={faCheck}
                /> Browser Detection
              </li>
              <li>
                <FontAwesomeIcon
                  className="text-color-success"
                  fixedWidth
                  icon={faCheck}
                /> Device Detection
              </li>
              <li>
                <FontAwesomeIcon
                  className="text-color-success"
                  fixedWidth
                  icon={faCheck}
                /> SSL Encrypted Enpoint
              </li>
              <li>
                <FontAwesomeIcon
                  className="text-color-danger"
                  fixedWidth
                  icon={faTimes}
                /> User Location Detection
              </li>
              <li className="gray">
                <PackageButton
                  stripeSubscriptionID={this.state.stripeSubscriptionID}
                  saveSubscription={this.state.saveSubscription}
                  stripeProdID={'plan_DgvAMptm2irepA'}
                />
              </li>
            </ul>
          </div>

          <div className="package-container">
            <ul className="package">
                <li className="header">Business</li>
                <li className="gray">$149.95/month</li>
                <li>250,000 Monthly Requests</li>
                <li>
                  <FontAwesomeIcon
                    className="text-color-success"
                    fixedWidth
                    icon={faCheck}
                  /> Browser Detection
                </li>
                <li>
                  <FontAwesomeIcon
                    className="text-color-success"
                    fixedWidth
                    icon={faCheck}
                  /> Device Detection
                </li>
                <li>
                  <FontAwesomeIcon
                    className="text-color-success"
                    fixedWidth
                    icon={faCheck}
                  /> SSL Encrypted Enpoint
                </li>
                <li>
                  <FontAwesomeIcon
                    className="text-color-success"
                    fixedWidth
                    icon={faCheck}
                  /> User Location Detection
                </li>
                <li className="gray">
                  <PackageButton
                    stripeSubscriptionID={this.state.stripeSubscriptionID}
                    saveSubscription={this.state.saveSubscription}
                    stripeProdID={'prod_DgvBY1tAYHSR1X'}
                  />
                </li>
              </ul>
          </div>

          <div className="package-container">
            <ul className="package">
                <li className="header">Enterprise</li>
                <li className="gray">$249.95/month</li>
                <li>1,000,000 Monthly Requests</li>
                <li>
                  <FontAwesomeIcon
                    className="text-color-success"
                    fixedWidth
                    icon={faCheck}
                  /> Browser Detection
                </li>
                <li>
                  <FontAwesomeIcon
                    className="text-color-success"
                    fixedWidth
                    icon={faCheck}
                  /> Device Detection
                </li>
                <li>
                  <FontAwesomeIcon
                    className="text-color-success"
                    fixedWidth
                    icon={faCheck}
                  /> SSL Encrypted Enpoint
                </li>
                <li>
                  <FontAwesomeIcon
                    className="text-color-success"
                    fixedWidth
                    icon={faCheck}
                  /> User Location Detection
                </li>
                <li className="gray">
                  <PackageButton
                    stripeSubscriptionID={this.state.stripeSubscriptionID}
                    saveSubscription={this.state.saveSubscription}
                    stripeProdID={'prod_DgvFTK71DoBvYn'}
                  />
                </li>
              </ul>
          </div>
        </div>
      </>
    );
  }

}

export default PricingTable;
