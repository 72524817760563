import React, { Component } from 'react';
import {
  Link,
  withRouter
} from 'react-router-dom';

import { SignUpLink } from './SignUp';
import { PasswordForgetLink } from './PasswordForget';
import { auth } from '../../firebase';
import * as routes from '../../constants/routes';

import AuthWrapper from '../shared/AuthWrapper';

const SignInPage = ({ history }) =>
  <AuthWrapper>
    <h3>Log In</h3>
    <SignInForm history={history} />
    <SignUpLink />
  </AuthWrapper>

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const {
      email,
      password,
    } = this.state;

    const {
      history,
    } = this.props;

    auth.doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        history.push(routes.DASHBOARD);
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      email,
      password,
      error,
    } = this.state;

    const isInvalid =
      password === '' ||
      email === '';

    return (
      <form onSubmit={this.onSubmit}>
        { error && <p className="alert alert-danger">{error.message}</p> }

        <label htmlFor="email">Email Address</label>
        <input
          autoFocus
          className="input-text"
          id="email"
          onChange={event => this.setState(byPropKey('email', event.target.value))}
          placeholder="email@domain.com"
          type="email"
          value={email}
        />

        <label htmlFor="password">Password</label>
        <input
          className="input-text"
          onChange={event => this.setState(byPropKey('password', event.target.value))}
          id="password"
          type="password"
          value={password}
          
        />
        <PasswordForgetLink />
        <button
          className="btn btn-block m-b-2"
          disabled={isInvalid}
          type="submit"
        >
          Log In
        </button>
      </form>
    );
  }
}

const SignInLink = () =>
  <p className="text-center m-b-0">
    Already have an account?
    {' '}
    <Link to={routes.LOG_IN}>Log In</Link>
  </p>

const RememberedPasswordSignInLink = () =>
  <p className="text-center m-b-0">
    Remembered your password?
    {' '}
    <Link to={routes.LOG_IN}>Log In</Link>
  </p>

export default withRouter(SignInPage);

export {
  SignInForm,
  SignInLink,
  RememberedPasswordSignInLink
};