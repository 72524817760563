import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { auth } from '../../firebase';
import * as routes from '../../constants/routes';

import { RememberedPasswordSignInLink } from './LogIn';
import AuthWrapper from '../shared/AuthWrapper';

const PasswordForgetPage = () =>
  <AuthWrapper>
    <h3>Reset Your Password</h3>
    <PasswordForgetForm />
    <RememberedPasswordSignInLink />
  </AuthWrapper>

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;

    auth.doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      email,
      error,
    } = this.state;

    const isInvalid = email === '';

    return (
      <form onSubmit={this.onSubmit}>
        { error && <p className="alert alert-danger">{error.message}</p> }

        <label htmlFor="email">Email Address</label>
        <input
          autoFocus
          className="input-text"
          id="email"
          onChange={event => this.setState(byPropKey('email', event.target.value))}
          placeholder="email@domain.com"
          type="email"
          value={this.state.email}
        />

        <button
          className="btn btn-block m-b-2"
          disabled={isInvalid}
          type="submit"
        >
          Reset My Password
        </button>
      </form>
    );
  }
}

const PasswordForgetLink = () =>
  <p className="text-right">
    <Link to={routes.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>

export default PasswordForgetPage;

export {
  PasswordForgetForm,
  PasswordForgetLink,
};