import React from 'react';

import { ReactComponent as Logo } from '../../assets/svg/user-agent-nav-logo.svg';
import SignOutButton from '../auth/SignOut';

const Header = () =>
  <header>
    <a href="#" className="logo">
      <Logo />
    </a>
    <div className="toolbar">
      {/*<button class="btn">Some Button</button>*/}
      <SignOutButton />
    </div>
  </header>

export default Header;
