import React, { Component } from 'react';
import {
  Link,
  withRouter
} from 'react-router-dom';

import { SignInLink } from './LogIn';
import { auth } from '../../firebase';
import * as routes from '../../constants/routes';

import AuthWrapper from '../shared/AuthWrapper';

const INITIAL_STATE = {
  email: '',
  passwordOne: '',
  error: null,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const SignUpPage = ({ history }) =>
  <AuthWrapper>
    <h3>Create an Account</h3>
    <SignUpForm history={history} />
    <SignInLink />
  </AuthWrapper>
  

class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const {
      email,
      passwordOne,
    } = this.state;

    const {
      history,
    } = this.props;

    auth.doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(function() {
        this.setState({ ...INITIAL_STATE });
        history.push(routes.DASHBOARD);
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      email,
      passwordOne,
      error,
    } = this.state;

    const isInvalid =
      passwordOne === '' ||
      email === '';

    return (
      <form onSubmit={this.onSubmit}>
        { error && <p className="alert alert-danger">{error.message}</p> }

        <label htmlFor="email">Email Address</label>
        <input
          autoFocus
          className="input-text"
          id="email"
          onChange={event => this.setState(byPropKey('email', event.target.value))}
          placeholder="email@domain.com"
          type="email"
          value={email}
        />

        <label htmlFor="password">Password</label>
        <input
          className="input-text"
          id="password"
          onChange={event => this.setState(byPropKey('passwordOne', event.target.value))}
          type="password"
          value={passwordOne}
        />
        <button
          className="btn btn-block m-b-2"
          disabled={isInvalid}
          type="submit"
        >
          Get Started
        </button>
      </form>
    );
  }
}

const SignUpLink = () =>
  <p className="text-center m-b-0">
    Don't have an account?
    {' '}
    <Link to={routes.SIGN_UP}>Sign Up</Link>
  </p>

export default withRouter(SignUpPage);

export {
  SignUpForm,
  SignUpLink,
};