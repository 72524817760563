import React, { Component } from 'react';

import withAuthorization from '../auth/withAuthorization';

import Header from '../authorized/Header';
import Navigation from '../authorized/Navigation';
import Footer from '../authorized/Footer';

import './AuthorizedWrapper.scss';

class AuthorizedWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      children: this.props.children
    }
  }

  render() {
    return (
      <>
        <div id="admin-container">
          <Header />
          <Navigation />
          <main>
            {this.state.children}
            <Footer />
          </main>
        </div>
      </>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(AuthorizedWrapper);
