import React, { Component } from 'react'

class Alert extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {message, type} = this.props;

    return (
      <div className={`alert alert-${type}`}>{message}</div>
    );
  }
}

export default Alert;
