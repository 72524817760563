import React from 'react';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';

import SignUpPage from '../auth/SignUp';
import LogInPage from '../auth/LogIn';
import PasswordForgetPage from '../auth/PasswordForget';
import DashboardPage from '../dashboard/Dashboard';
import AccountPage from '../account/Account';
import SubscriptionPage from '../subscription/Subscription';

import * as routes from '../../constants/routes';

import withAuthentication from '../auth/withAuthentication';

import './App.scss';

const App = () =>
  <Router>
    <>
      <Route
        exact path={routes.SIGN_UP}
        component={SignUpPage}
      />
      <Route
        exact path={routes.LOG_IN}
        component={LogInPage}
      />
      <Route
        exact path={routes.PASSWORD_FORGET}
        component={PasswordForgetPage}
      />
      <Route
        exact path={routes.DASHBOARD}
        component={DashboardPage}
      />
      <Route
        exact path={routes.ACCOUNT}
        component={AccountPage}
      />
      <Route
        exact path={routes.SUBSCRIPTION}
        component={SubscriptionPage}
      />
    </>
  </Router>

export default withAuthentication(App);