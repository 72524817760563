import React from 'react';

import { auth } from '../../firebase';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons';

const SignOutButton = () =>
  <a
    className="log-out"
    href="#"
    onClick={auth.doSignOut}
  >

    <FontAwesomeIcon
      fixedWidth
      icon={faSignOut}
    /> Log Out
  </a>

export default SignOutButton;