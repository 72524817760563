import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

var config = {
  apiKey: "AIzaSyBHmuN4zcsRbm-m_oS2FSVtUDRb2KBzNZA",
  authDomain: "user-agent-api-io.firebaseapp.com",
  databaseURL: "https://user-agent-api-io.firebaseio.com",
  projectId: "user-agent-api-io",
  storageBucket: "user-agent-api-io.appspot.com",
  messagingSenderId: "150228836038"
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const db = firebase.database();

export {
  auth,
  db
};